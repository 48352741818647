<template>
    <section class="content">
        <div class="row mb-2">
          <div class="col-md-12">
            <div class="info-box mb-3">
              <div class="info-box-content">
                <div class="row">
                  <div class="col-md-12">
                      <div class="text-muted mb-1 font-13 text-uppercase flex">
                          JUDUL
                      </div>
                      <h4 class="mb-1">
                      <span> {{ dataUjian.ujian_title }} </span>
                      </h4>
                  </div>
                  <div class="col-md-12">
                      <div class="text-muted mb-1 font-13 text-uppercase flex">
                          JENIS TES
                      </div>
                      <h4 class="mb-1">
                      <span> {{ dataUjian.mapel }} </span>
                      </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-info">
            <div class="card-header">
                <div class="box-tools card-tools toolbar">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" aria-haspopup="true" aria-expanded="false" data-toggle="modal" data-target="#exampleModal">
                            <i class="fas fa-plus"></i> Buat Soal
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-4" v-for="item in generated">
                  <div class="card" style="border: #CDCDCD 1px solid;">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-2">
                          <div style="width: 40px; height: 40px; background-color: #DCF0FA; border-radius: 4px; display: flex; align-items: center;">
                            <!-- <img src="@/assets/img/huruf_icon.png" alt="" srcset=""> -->
                            <img :src="imageSrc(item.tipe)" class="mx-auto">
                          </div>
                        </div>
                        <div class="col-sm-10">
                          <p class="mb-0" style="font-weight: 700;font-size: 16px;line-height: 20px;">{{ dataUjian.mapel }}</p>
                          <p class="mb-0" style="font-weight: 400;font-size: 14px;line-height: 18px;color: #39A2DB;">{{ item.tipe }}</p>
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-sm-3">
                          <div class="w-100 text-center">
                            <p class="mb-0" style="font-weight: 700;font-size: 14px;line-height: 18px;">{{ item.total_col }}</p>
                            <p class="mb-0" style="font-weight: 400;size: 10px; color: rgba(53, 53, 53, 0.6);">Kolom</p>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="w-100 text-center">
                            <p class="mb-0" style="font-weight: 700;font-size: 14px;line-height: 18px;">{{ item.total_row }}</p>
                            <p class="mb-0" style="font-weight: 400;size: 10px; color: rgba(53, 53, 53, 0.6);">Baris</p>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="w-100 text-center">
                            <p class="mb-0" style="font-weight: 700;font-size: 14px;line-height: 18px;">{{ item.total_col * item.total_row }}</p>
                            <p class="mb-0" style="font-weight: 400;size: 10px; color: rgba(53, 53, 53, 0.6);">Total Soal</p>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="w-100 text-center">
                            <p class="mb-0" style="font-weight: 700;font-size: 14px;line-height: 18px;">{{ item.total_duration }} Menit</p>
                            <p class="mb-0" style="font-weight: 400;size: 10px; color: rgba(53, 53, 53, 0.6);">Per Kolom</p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2">
                          <button class="btn btn-outline-danger" @click="deleteSoal(item.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                        </div>
                        <div class="col-sm-10">
                          <button type="button" @click="navigateToDetail(item.id)" class="btn bg-primary w-100">Lihat Soal</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <router-link
                :to="{ path: '/list-ujian' }"
                class="btn btn-secondary"
                >
                Kembali
                </router-link>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form role="form"  @submit.prevent="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Buat Soal Test Kecermatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Tipe</label>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" v-model="form.tipe" value="1">
                                            <label class="form-check-label" for="inlineRadio1">Angka</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" v-model="form.tipe" value="2">
                                            <label class="form-check-label" for="inlineRadio2">Huruf</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" v-model="form.tipe" value="3">
                                            <label class="form-check-label" for="inlineRadio3">Simbol</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Jumlah Kolom</label>
                                        <input type="number" min="1" max="6" class="form-control" v-model="form.jumlah_kolom" name="i_jml_kolom" placeholder="Jumlah Kolom">
                                        <div class="text-sm text-danger">
                                          * Jumlah kolom maksimal 6
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Jumlah Baris (Soal) Per-Kolom</label>
                                        <input type="number" min="1" max="80" class="form-control" v-model="form.jumlah_baris" name="i_jml_baris" placeholder="Jumlah baris">
                                        <div class="text-sm text-danger">
                                          * Jumlah baris maksimal 80
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Lama Pengerjaan Per-Kolom (Menit)</label>
                                        <input type="text" class="form-control" v-model="form.jumlah_menit" name="i_menit" placeholder="Lama Pengerjaan">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" :disabled=disabledBtn>Generate</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
  </template>
  
  <script>
  import { createTable, authFetch } from "@/libs/hxcore";
  import Swal from "sweetalert2";
  import $ from "jquery";
  import vSelect from 'vue-select';
  import "vue-select/dist/vue-select.css";
  import Daterangepicker from 'daterangepicker';
  import "daterangepicker/daterangepicker.css";
  import moment from "moment";
  import angkaIcon from '@/assets/img/angka_icon.png';
  import hurufIcon from '@/assets/img/huruf_icon.png';
  import simbolIcon from '@/assets/img/simbol_icon.png';
  
  export default {
    name: "",
    data() {
      return {
        disabledBtn: false,
        dataUjian: {},
        generated: [],
        errors: [],
        optMapel: [],
        method: "",
        roles: "",
        ujian_id: "",
        formTitle: "Tambah",
        form: {
          tipe: "",
          jumlah_kolom: "",
          jumlah_baris: "",
          jumlah_menit: "",
          ujian_id: this.$route.params.id,
        },
      };
    },
    created: function () {
      this.roles = this.$route.meta.roles;
      console.log("load initial data", this.$route);
      this.ujian_id = this.$route.params.id;
  
      this.loadDetail(this.$route.params.id);
    },
    components: { 
      vSelect, 
    },
    methods: {
      navigateToDetail(event) {
        console.log(event)
        this.$router.push("/detail-ujian/psikotes-detail/" + event);
      },
      loadDetail: function(id){
        authFetch("/akademik/ujian/data_ujian_psikologi/" + id).then((res) => {
          res.json().then((json) => {
            this.dataUjian = json.data;
            this.generated = json.generated
                console.log('joss',this.generated);
          });
        });
      },
      submitForm: function (ev) {
        // this.disabledBtn = true;
        const e = this.$refs;
  
        var data = Object.keys(this.form)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
          )
          .join("&");
        var urlSubmit = "/akademik/ujian/generate_psikologi";
        if (this.method == "PUT")
          urlSubmit = "/akademik/ujian/generate_psikologi/" + this.form.id;
  
        authFetch(urlSubmit, {
          method: "POST",
          body: data,
        })
          .then((res) => {
            console.log(res)
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            $('#exampleModal').modal('hide')
            // this.table.api().ajax.reload();
            // $(e.formDialog).modal("hide");
            this.disabledBtn = false;
            location.reload();
            return res.json();
          })
          .then((js) => {
            this.errors = [];
            if (!js.success) {
              console.log(js.details);
  
              for (var key in js.details) {
                if (js.details.hasOwnProperty(key)) {
                  this.errors.push(js.details[key]);
                }
              }
  
              return;
            }
            $('#exampleModal').modal('hide')
            // this.table.api().ajax.reload();
            // $(e.formDialog).modal("hide");
            this.disabledBtn = false;
          });
  
        ev.preventDefault();
      },
      truncate: function (string, limit) {
        if (string.length <= limit) {
          return string;
        }
        return string.slice(0, limit) + "...";
      },
      handleClick(e) {
        if (e.target.matches(".link-role")) {
          this.$router.push({ path: "/permit/" + e.target.dataset.id });
          return false;
        }
  
        if (e.target.closest("button")) {
            if (e.target.dataset.action == "edit") {
              let id = e.target.dataset.id;
              this.$router.push({ path: `/soal/edit/${id}`, params: {ujian_id: this.$route.params.id}});
            } else if (e.target.dataset.action == "hapus") {
                Swal.fire({
                title: "Hapus soal?",
                icon: "question",
                denyButtonText: '<i class="fa fa-times"></i> Hapus',
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
              }).then((result) => {
                if (result.isDenied) {
                  authFetch("/akademik/ujian/soal/" + e.target.dataset.id, {
                    method: "DELETE",
                    body: "id=" + e.target.dataset.id,
                  })
                    .then((res) => {
                      return res.json();
                    })
                    .then((js) => {
                      this.table.api().ajax.reload();
                    });
                }
              });
            }
        }
      },
      deleteSoal(e) {
        authFetch("/akademik/ujian/delete_generated_psikologi/"+e,{
          method: 'POST',
        }).then((res) => {
          res.json().then((json) => {
            this.$router.go();
          })
        })
      },
      imageSrc(e) {
        var src = ''
        if (e == 'Angka') {
          // src = '@/assets/img/angka_icon.png';
          src = angkaIcon;
        } else if(e == 'Huruf') {
          // src = '@/assets/img/huruf_icon.png';
          src = hurufIcon;
        } else {
          // src = '@/assets/img/simbol_icon.png';
          src = simbolIcon;
        }
        return src
      }
    },
    mounted() {
        const e = this.$refs;
        let self = this;
        new Daterangepicker(this.$refs.daterange, {
          startDate: moment(),
          endDate: moment().day(2),
          }, function(d1, d2) {
              self.form.tanggal_mulai = d1.format('DD/MM/YYYY')
              self.form.tanggal_selesai = d2.format('DD/MM/YYYY')
          }
        );
    },
  };
  </script>
  